/*
 * @Author: Tiger
 * @Date: 2020-07-10 07:42:03
 * @LastEditTime: 2021-01-15 20:58:34
 * @LastEditors: Please set LastEditors
 * @Description: Project caese
 * @FilePath: /official_website/src/pages/project.js
 */ 
import React, { useEffect } from 'react'
import url from 'url'
import { Container, Row, Col, Carousel } from 'react-bootstrap'

import { PhotoProvider, PhotoConsumer, PhotoSlider } from 'react-photo-view'
import 'react-photo-view/dist/index.css'

import Layout from '../components/layout'

const intro011 = (
    <div>
        <div>With complete functions, it also has the functions of bedroom, 
            living room and bathroom. Can be used as a residence, 
            hotel, ADU, emergency disaster relief placement.</div>
        <div>Faster construction process, using box structure, 
            high degree of factory prefabrication, and fast on-site construction.</div>
        <div>...</div>
    </div>
)

const intro1 = (
    <div>
        <div>With complete functions, it also has the functions of bedroom, 
            living room and bathroom. Can be used as a residence, 
            hotel, ADU, emergency disaster relief placement.</div>
        <div>Faster construction process, using box structure, 
            high degree of factory prefabrication, and fast on-site construction.</div>
        <div>This design can be customized, 
            and the exterior can be decorated with cement board in a variety of colors, 
            or alternatively, with customized aluminum plates or other materials.</div>
    </div>
)

const intro021 = (
    <div>
        <div>Customized design that incorporates Hungarian-style shapes, patterns, 
            and materials to create cost-effective and exclusive apartments.</div>
        <div>Fully functional, both horizontal, living room, toilet functions, 
            can be used as residential, hotel, rescue and disaster relief resettlement.  </div>
        <div>...</div>
    </div>
)

const intro2 = (
    <div>
        <div>Customized design that incorporates Hungarian-style shapes, patterns, 
            and materials to create cost-effective and exclusive apartments.</div>
        <div>Fully functional, both horizontal, living room, toilet functions, can be used as residential, hotel, rescue and disaster relief resettlement.  </div>
        <div>Fast construction, the use of box structure, high degree of factory prefabrication, quick construction site. </div>
    </div>
)

const intro031 = (
    <div>
        <div>To enhance the living environment for mining workers, the dormitory and office functions will be improved.</div>
        <div>The development will include a comprehensive range of functions 
            such as collective and single dormitory accommodation, canteen, church, 
            laundry, retail, office building, and supporting facilities. </div>
        <div>...</div>
    </div>
)

const intro3 = (
    <div>
        <div>To enhance the living environment for mining workers, the dormitory and office functions will be improved.</div>
        <div>The development will include a comprehensive range of functions 
            such as collective and single dormitory accommodation, canteen, church, 
            laundry, retail, office building, and supporting facilities. </div>
        <div>The project will be constructed using a box structure, 
            which will be prefabricated to a high degree in the factory, 
            resulting in rapid on-site construction.</div>
    </div>
)

const intro041 = (
    <div>
        <div>This versatile unit can be used for a variety of scenarios. 
            It comes in two sizes: 9740*6055 and 12425*4870, 
            and includes bedroom, living room, and bathroom functions, 
            making it suitable for use as a residence, hotel, 
            ADU, emergency shelter, or disaster relief housing. </div>
        <div>...</div>
    </div>
)


const intro4 = (
    <div>
        <div>This versatile unit can be used for a variety of scenarios. 
            It comes in two sizes: 9740*6055 and 12425*4870, 
            and includes bedroom, living room, and bathroom functions, 
            making it suitable for use as a residence, hotel, 
            ADU, emergency shelter, or disaster relief housing. </div>
        <div>The construction is fast due to the box-type house structure, 
            which is prefabricated to a high degree in the factory and allows for rapid on-site assembly. 
        </div>
        <div>The structural frame can be completed in just 15 minutes.</div>
    </div>
)

const intro051 = (
    <div>
        <div>We have enhanced the privacy and created a more formal atmosphere by adding a patio to the porch.</div>
        <div>The integrated dining and living room spaces provide an ideal setting for gatherings and entertaining. </div>
        <div>The design of our restaurant is customized to suit the living habits of both European and 
            American customers, and the outdoor patio adds to its beauty and functionality. </div>
        <div>...</div>
    </div>
)

const intro5 = (
    <div>
        <div>We have enhanced the privacy and created a more formal atmosphere by adding a patio to the porch.</div>
        <div>The integrated dining and living room spaces provide an ideal setting for gatherings and entertaining. </div>
        <div>The design of our restaurant is customized to suit the living habits of both European and 
            American customers, and the outdoor patio adds to its beauty and functionality. </div>
        <div>Each functional area is independent and complete, and the presence of a back door makes it more convenient.</div>
    </div>
)

const intro061 = (
    <div>
        <div>Efficient:</div>
        <div>Our modular products are designed and manufactured for quick delivery, 
            either as a complete unit or packaged for easy transportation to your site. 
            The assembly process is fast, reducing construction time significantly. 
            Installing a 3*6m frame only takes 15 minutes, 
            making it easier to get your project up and running.</div>
        <div>...</div>
    </div>
)

const intro6 = (
    <div>
        <div>Efficient:</div>
        <div>Our modular products are designed and manufactured for quick delivery, 
            either as a complete unit or packaged for easy transportation to your site. 
            The assembly process is fast, reducing construction time significantly. 
            Installing a 3*6m frame only takes 15 minutes, 
            making it easier to get your project up and running.</div>
        <div>Affordable:</div>
        <div>Our modular products are produced on an advanced assembly line, 
            ensuring consistent quality and lower production costs. 
            With less on-site construction required, you can save on labor costs. 
            Our products are also affordable, 
            reducing your project's overall lifecycle cost without sacrificing quality.</div>
        <div>Flexible:</div>
        <div>Our modular products are highly industrialized, 
            making them easy to repurpose or transfer to other projects. 
            This flexibility allows you to adapt to changing needs and reduces future project costs. 
            Our products provide the freedom to adjust and grow without expensive demolitions and rebuilds.</div>
    </div>
)

const intro071 = (
    <div>
        <div>Revolutionize Your Living Space with Our Modular Building Products</div>
        <div>Our modular building products offer a range of flexible residential spaces 
            that can be tailored to meet your unique requirements. 
            Whether you're seeking a cozy single room or a spacious three-bedroom, 
            our standardized product portfolio makes it easy to create the perfect layout for your family.</div>
        <div>...</div>
    </div>
)

const intro7 = (
    <div>
        <div>Revolutionize Your Living Space with Our Modular Building Products</div>
        <div>Our modular building products offer a range of flexible residential spaces 
            that can be tailored to meet your unique requirements. 
            Whether you're seeking a cozy single room or a spacious three-bedroom, 
            our standardized product portfolio makes it easy to create the perfect layout for your family.</div>
        <div>Cost-Effective and High-Quality Construction</div>
        <div>We manufacture our products in a cutting-edge factory assembly line, 
            ensuring consistent quality and efficient production. 
            This results in reduced costs and less on-site labor, saving you time and money. 
            Furthermore, our high level of industrialization and quality means 
            that the construction period is shortened, 
            enabling convenient and cost-effective project turnover.</div>
        <div>Experience a faster, more affordable, 
            and higher quality building process with our modular building products. 
            Contact us today to learn more about our products and how they can benefit you.</div>
    </div>
)

const intro081 = (
    <div>
        <div>As the housing shortage problem becomes increasingly urgent, 
            the demand for affordable housing is on the rise. 
            Standardized housing products and industrialized construction 
            methods can enable large-scale supply of cost-effective housing 
            while providing a superior living experience to residents.</div>
        <div>...</div>
    </div>
)

const intro8 = (
    <div>
        <div>As the housing shortage problem becomes increasingly urgent, 
            the demand for affordable housing is on the rise. 
            Standardized housing products and industrialized construction 
            methods can enable large-scale supply of cost-effective housing 
            while providing a superior living experience to residents.</div>
        <div>Our modular housing products are manufactured on assembly lines in the factory, 
            which ensures consistent quality and high production efficiency. 
            This reduces production costs, minimizes on-site labor, 
            and results in significant savings in project life cycle costs.</div>
        <div>Our highly industrialized approach guarantees superior quality 
            and allows for short construction periods, 
            which in turn enable faster project turnover and seamless transitions to other projects. 
            This helps to further reduce overall life cycle costs 
            while delivering top-notch living spaces to our valued customers.</div>
    </div>
)

const intro9 = (
    <div>
        <div>Project time：November 2019</div>
        <div>Project location:Shijingshan District,Beijing</div>
        <div>Composition: 134 boxes</div>
        <div>Building area: 2412 ㎡</div>
        <div>Duration: 32 Days</div>
        <div>Purposes: Meeting,reception,exhibition,etc</div>
        <div>High-powered air conditioning unit of Panasonic</div>
        <div>Comprehensive and fine management </div>
        <div>People-oriented through multiple perspectives to deep experience</div>
        <div>Offer assistance to Olympic Committee</div>
        <div>Our product can be applied to many fields such as culture,Sports, Entertainment, Exhibition,etc.</div>
    </div>
)

const intro91 = (
    <div>
        <div>Project time：November 2019</div>
        <div>Project location:Shijingshan District,Beijing</div>
        <div>Composition: 134 boxes</div>
        <div>Building area: 2412 ㎡</div>
        <div>Duration: 32 Days</div>
        <div>Purposes: Meeting,reception,exhibition,etc.</div>
    </div>
)

const intro10 = (
    <div>
        <div>Project time：October 2020</div>
        <div>Project location：Hangzhou, ZheJiang Province</div>
        <div>Area covered：144㎡</div>
        <div>Duration: 17 Days</div>
        <div>Room composition：22 boxes + 6 sets of bottom frame</div>
        <div>Purpose of the Project：Reception, exhibition, catering, retail, etc.</div>
        <div>
            Based on the understanding and analysis of the project background, 
            the core keywords and design concept of the project are deeply centered on 
            solving the core problems of the project with limited area, complicated functions, 
            crossed streamline and high quality requirements.Therefore, the scheme design is 
            based on the modernist architectural design concept of "Less is more" 
            and the scientific principle of "Mobius Ring". Through the division of the inside and outside, 
            the internal and external traffic flow lines are added to realize the double 
            length of the streamline and expand the visiting area effectively. In addition, 
            while dividing the internal and external streamline lines, the interior design, 
            such as architectural style and materials, also switches between the industrial 
            style and the Japanese natural style simultaneously, using the means of conflict 
            to increase the user's sense of space enlargement.
        </div>
    </div>
)

const intro101 = (
    <div>
        <div>Project time：October 2020</div>
        <div>Project location：Hangzhou, ZheJiang Province</div>
        <div>Area covered：144㎡</div>
        <div>Duration: 17 Days</div>
        <div>Room composition：22 boxes + 6 sets of bottom frame</div>
        <div>Purpose of the Project：Reception, exhibition, catering, retail, etc.</div>
    </div>
)

const intro11 = (
    <div>
        <div>Project time：April 2019</div>
        <div>Project location：Beijing Glodon</div>
        <div>Area covered：150㎡</div>
        <div>Room composition：20 boxes</div>
        <div>Duration: 30 Days</div>
        <div>Purpose of the Project：Office, reception, etc.</div>
    </div>
)

const intro12 = (
    <div>
        <div>Project time：May 2020</div>
        <div>Project location：Langfang, Hebei Province</div>
        <div>Room composition：258 boxes</div>
        <div>Building area: 4330 ㎡</div>
        <div>Duration: 36 Days</div>
        <div>Purpose of the Project：Office, meeting, reception, exhibition, etc.</div>
    </div>
)

const intro13 = (
    <div>
        <div>Project time：May 2021</div>
        <div>Project location：Hangzhou, Zhejiang Province</div>
        <div>Duration: 45Days</div>
        <div>Area covered：791㎡</div>
        <div>Purpose of the Project：Office, meeting, reception, exhibition, etc.</div>
    </div>
)

const intro14 = (
    <div>
        <div>Project time：August 2021</div>
        <div>Project location:Panasonic Industrial Park, Hangzhou, Zhejiang</div>
        <div>Composition: 21 boxes</div>
        <div>Building area: 430 ㎡</div>
        <div>Duration: 35Days</div>
        <div>Purposes: live delivery,office,exhibition, etc.</div>
    </div>
)

const intro15 = (
    <div>
        <div>Project time：August 2021</div>
        <div>Project location:Xi 'an Airport, Shanxi Province</div>
        <div>Composition: 113 boxes</div>
        <div>Building area: 2850 ㎡</div>
        <div>Duration: 20 Days</div>
        <div>Purposes: Office,conference,restaurant,accommodation,etc.</div>
    </div>
)

const intro16 = (
    <div>
        <div>Project time：July 2020</div>
        <div>Project location:Nanjing, Jiangsu Province</div>
        <div>Composition: 3 boxes</div>
        <div>Building area: 54㎡+5.4㎡</div>
        <div>Duration: 6 Days</div>
        <div>Purposes: Convenience store</div>
    </div>
)

const intro17 = (
    <div>
        <div>Project time：January 2021</div>
        <div>Project location:Hangzhou, Zhejiang Province</div>
        <div>Composition: 1 box</div>
        <div>Building area: 18㎡</div>
        <div>Duration: 2 Days</div>
        <div>Purposes: leisure tea break，retail，convenience store</div>
        <div>
            The box structure is light steel integrated house, 
            and the enclosure structure is wallboard + glass curtain wall. 
            The decoration part is borne by the customer
        </div>
    </div>
)

const intro171 = (
    <div>
        <div>Project time：January 2021</div>
        <div>Project location:Hangzhou, Zhejiang Province</div>
        <div>Composition: 1 box</div>
        <div>Building area: 18㎡</div>
        <div>Duration: 2 Days</div>
        <div>Purposes: leisure tea break，retail，convenience store</div>
    </div>
)

const intro18 = (
    <div>
        <div>Project time：March 2021</div>
        <div>Project location:Panasonic Industrial Park, Hangzhou</div>
        <div>Composition: 5 boxes</div>
        <div>Building area: 87㎡</div>
        <div>Duration: 10 Days</div>
        <div>Purposes: Reception,exhibition of urban life,etc.</div>
    </div>
)

const intro191 = (
    <div>
        <div>Project time：June 2020</div>
        <div>Project location：Jiangyin， Jiangsu Province</div>
        <div>Area covered：386.8㎡</div>
        <div>Room composition：25 boxes + 200㎡ steel structure</div>
        <div>Duration: 30 Days</div>
        <div>Purpose of the Project：Office, reception etc.</div>
    </div>
)

const intro19 = (
    <div>
        <div>Project time：June 2020</div>
        <div>Project location：Jiangyin， Jiangsu Province</div>
        <div>Area covered：386.8㎡</div>
        <div>Room composition：25 boxes + 200㎡ steel structure</div>
        <div>Purpose of the Project：Office, reception etc.</div>
        <div>
            1. Environmentally friendly
            2. The combination structure of steel structure and box-type room is 
            constructed in the mode of space station.
            3. Integration of hardware and software: 
            in our opinion, architectural logic consists of components → parts → rooms → buildings → parks, 
            so our cognition is different from that of traditional architecture. 
            Looking forward to the integration of hardware and software in the future, 
            building a digital platform based on components and opening up the whole industrial chain; 
            From design to production to construction, as well as the intermediate material 
            management and distribution, the whole process is handled digitally and seamlessly, 
            and the building is manufactured as an industrial product to solve the 
            contradiction between standardization and individuation, enabling the industry.
        </div>
    </div>
)

const intro20 = (
    <div>
        <div>Project time：March 2021</div>
        <div>Project location:Xinxiang City, Henan Province</div>
        <div>Composition: 22 boxes</div>
        <div>Building area: 557.7 ㎡</div>
        <div>Duration: 19 Days</div>
        <div>Purposes: Office,reception,exhibition, etc.</div>
    </div>
)

const intro21 = (
    <div>
        <div>Project time：August 2019</div>
        <div>Project location：Beijing Water Cube Olympic Park</div>
        <div>Room composition：4 boxes</div>
        <div>Purpose of the Project：Intelligent exhibition, etc.</div>
    </div>
)

const intro22 = (
    <div>
        <div>Project time： July 2020</div>
        <div>Project location:Hefeng County, Hubei Province</div>
        <div>Composition: 92 boxes</div>
        <div>Building area: 1656 ㎡</div>
        <div>Duration: 20 Days</div>
        <div>Purposes: Office,reception,exhibition, etc.</div>
    </div>
)

const intro23 = (
    <div>
        <div>Project time： January 2022</div>
        <div>Project location:Xi'an City, Shanxi Province</div>
        <div>Composition: 1,935 boxes</div>
        <div>Building area: 35,000 ㎡</div>
        <div>Duration: 3 Days</div>
        <div>Purposes: Quarantine hospital</div>
    </div>
)

const caseList = [
    {
        title: 'New Zealand single box 14㎡ of small housing',
        intro: intro011,
        class: 'line2',
        // ['camp', 'china', 'rescue', 'residential', 'game']
        type: ['rescue', 'residential', 'game'],
        name: 'project1',
        imgList: [
            {
                'url':'/project/cases/project011.png', 
                'intro':intro1, 
                'title':'New Zealand single box 14㎡ of small housing'
            },
            {
                'url':'/project/cases/project012.png', 
                'intro':intro1, 
                'title':'New Zealand single box 14㎡ of small housing'
            },
            {
                'url':'/project/cases/project013.png', 
                'intro':intro1, 
                'title':'New Zealand single box 14㎡ of small housing'
            },
        ]
    },
    {
        title: 'Double-storey apartment project in Hungary',
        intro: intro021,
        class: 'line2',
        type: ['rescue', 'residential', 'game'],
        name: 'project2',
        imgList: [
            {
                'url':'/project/cases/project021.png', 
                'intro':intro2, 
                'title':'Double-storey apartment project in Hungary'
            },
            {
                'url':'/project/cases/project022.png', 
                'intro':intro2, 
                'title':'Double-storey apartment project in Hungary'
            },
            {
                'url':'/project/cases/project023.png', 
                'intro':intro2, 
                'title':'Double-storey apartment project in Hungary'
            },
            {
                'url':'/project/cases/project024.png', 
                'intro':intro2, 
                'title':'Double-storey apartment project in Hungary'
            },
            {
                'url':'/project/cases/project025.png', 
                'intro':intro2, 
                'title':'Double-storey apartment project in Hungary'
            },
        ]
    },
    {
        title: 'Indonesia coal mine camp project',
        intro: intro031,
        class: 'line1',
        type: ['camp', 'rescue'],
        name: 'project3',
        imgList: [
            {
                'url':'/project/cases/project031.png', 
                'intro':intro3, 
                'title':'Indonesia coal mine camp project'
            },
            {
                'url':'/project/cases/project032.png', 
                'intro':intro3, 
                'title':'Indonesia coal mine camp project'
            },
            {
                'url':'/project/cases/project033.png', 
                'intro':intro3, 
                'title':'Indonesia coal mine camp project'
            },
            {
                'url':'/project/cases/project034.png', 
                'intro':intro3, 
                'title':'Indonesia coal mine camp project'
            },
        ]
    },
    {
        title: 'Flood control and disaster relief temporary housing project in Pakistan',
        intro: intro041,
        class: 'line2',
        type: ['rescue', 'residential'],
        name: 'project4',
        imgList: [
            {
                'url':'/project/cases/project041.png', 
                'intro':intro4, 
                'title':'Flood control and disaster relief temporary housing project in Pakistan'
            },
            {
                'url':'/project/cases/project042.png', 
                'intro':intro4, 
                'title':'Flood control and disaster relief temporary housing project in Pakistan'
            },
            {
                'url':'/project/cases/project043.png', 
                'intro':intro4, 
                'title':'Flood control and disaster relief temporary housing project in Pakistan'
            },
            {
                'url':'/project/cases/project044.png', 
                'intro':intro4, 
                'title':'Flood control and disaster relief temporary housing project in Pakistan'
            },
            {
                'url':'/project/cases/project045.png', 
                'intro':intro4, 
                'title':'Flood control and disaster relief temporary housing project in Pakistan'
            },
            {
                'url':'/project/cases/project046.png', 
                'intro':intro4, 
                'title':'Flood control and disaster relief temporary housing project in Pakistan'
            },
            {
                'url':'/project/cases/project047.png', 
                'intro':intro4, 
                'title':'Flood control and disaster relief temporary housing project in Pakistan'
            },
            {
                'url':'/project/cases/project048.png', 
                'intro':intro4, 
                'title':'Flood control and disaster relief temporary housing project in Pakistan'
            },
            {
                'url':'/project/cases/project049.png', 
                'intro':intro4, 
                'title':'Flood control and disaster relief temporary housing project in Pakistan'
            },
        ]
    },
    {
        title: '828 feet American style ADUs',
        intro: intro051,
        class: 'line1',
        type: ['residential'],
        name: 'project5',
        imgList: [
            {
                'url':'/project/cases/project051.png', 
                'intro':intro5, 
                'title':'828 feet American style ADUs'
            },
            {
                'url':'/project/cases/project052.png', 
                'intro':intro5, 
                'title':'828 feet American style ADUs'
            },
            {
                'url':'/project/cases/project053.png', 
                'intro':intro5, 
                'title':'828 feet American style ADUs'
            },
            {
                'url':'/project/cases/project054.png', 
                'intro':intro5, 
                'title':'828 feet American style ADUs'
            },
            {
                'url':'/project/cases/project055.png', 
                'intro':intro5, 
                'title':'828 feet American style ADUs'
            },
        ]
    },
    {
        title: 'Indonesian workers dormitory project',
        intro: intro061,
        class: 'line1',
        type: ['camp','rescue'],
        name: 'project6',
        imgList: [
            {
                'url':'/project/cases/project061.png', 
                'intro':intro6, 
                'title':'Indonesian workers dormitory project'
            },
            {
                'url':'/project/cases/project062.png', 
                'intro':intro6, 
                'title':'Indonesian workers dormitory project'
            },
        ]
    },
    {
        title: 'Residential project in Indonesia',
        intro: intro071,
        class: 'line1',
        type: ['rescue', 'residential', 'game'],
        name: 'project7',
        imgList: [
            {
                'url':'/project/cases/project071.png', 
                'intro':intro7, 
                'title':'Residential project in Indonesia'
            },
            {
                'url':'/project/cases/project072.png', 
                'intro':intro7, 
                'title':'Residential project in Indonesia'
            },
            {
                'url':'/project/cases/project073.png', 
                'intro':intro7, 
                'title':'Residential project in Indonesia'
            },
        ]
    },
    {
        title: 'Indonesia affordable housing pilot project',
        intro: intro081,
        class: 'line2',
        type: ['rescue', 'residential', 'game'],
        name: 'project8',
        imgList: [
            {
                'url':'/project/cases/project081.png', 
                'intro':intro8, 
                'title':'Indonesia affordable housing pilot project'
            },
            {
                'url':'/project/cases/project082.png', 
                'intro':intro8, 
                'title':'Indonesia affordable housing pilot project'
            },
            {
                'url':'/project/cases/project083.png', 
                'intro':intro8, 
                'title':'Indonesia affordable housing pilot project'
            },
            {
                'url':'/project/cases/project084.png', 
                'intro':intro8, 
                'title':'Indonesia affordable housing pilot project'
            },
        ]
    },
    {
        title: 'Supporting Function Building of Shougang Ski Jumps',
        intro: intro91,
        class: 'line2',
        type: ['rescue', 'game'],
        name: 'project9',
        imgList: [
            {
                'url':'/project/cases/project091.jpg', 
                'intro':intro9, 
                'title':'Supporting Function Building of Shougang Ski Jumps'
            },
            {
                'url':'/project/cases/project092.jpg', 
                'intro':intro9, 
                'title':'Supporting Function Building of Shougang Ski Jumps'
            },
            {
                'url':'/project/cases/project093.jpg', 
                'intro':intro9, 
                'title':'Supporting Function Building of Shougang Ski Jumps'
            },
            {
                'url':'/project/cases/project094.png', 
                'intro':intro9, 
                'title':'Supporting Function Building of Shougang Ski Jumps'
            },
            {
                'url':'/project/cases/project095.jpg', 
                'intro':intro9, 
                'title':'Supporting Function Building of Shougang Ski Jumps'
            },
            {
                'url':'/project/cases/project096.jpg', 
                'intro':intro9, 
                'title':'Supporting Function Building of Shougang Ski Jumps'
            },
            {
                'url':'/project/cases/project097.jpg', 
                'intro':intro9, 
                'title':'Supporting Function Building of Shougang Ski Jumps'
            },
            {
                'url':'/project/cases/project098.png', 
                'intro':intro9, 
                'title':'Supporting Function Building of Shougang Ski Jumps'
            },
        ]
    },
    {
        title: 'Lab4 Magichouse，Hangzhou',
        intro: intro101,
        class: 'line1',
        type: ['residential'],
        name: 'project10',
        imgList: [
            {
                'url':'/project/cases/project101.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project102.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project103.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project104.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project105.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project106.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project107.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project108.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project109.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project1010.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project1011.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project1012.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project1013.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project1014.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project1015.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project1016.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project1018.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project1019.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project1020.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
            {
                'url':'/project/cases/project1021.jpg', 
                'intro':intro10, 
                'title':'Lab4 Magichouse，Hangzhou'
            },
        ]
    },
    {
        title: 'Lab2 Beijing Experimental Building',
        intro: intro11,
        class: 'line1',
        type: ['rescue','residential'],
        name: 'project11',
        imgList: [
            {
                'url':'/project/cases1/project111.jpg', 
                'intro':intro11, 
                'title':'Lab2 Beijing Experimental Building'
            },
            {
                'url':'/project/cases1/project112.jpg', 
                'intro':intro11, 
                'title':'Lab2 Beijing Experimental Building'
            },
            {
                'url':'/project/cases1/project113.jpg', 
                'intro':intro11, 
                'title':'Lab2 Beijing Experimental Building'
            },
            {
                'url':'/project/cases1/project114.jpg', 
                'intro':intro11, 
                'title':'Lab2 Beijing Experimental Building'
            },
        ]
    },
    {
        title: 'The camp of Jingxiong Highway project built by CQCC',
        intro: intro12,
        class: 'line2',
        type: ['camp', 'rescue'],
        name: 'project12',
        imgList: [
            {
                'url':'/project/cases1/project121.jpg', 
                'intro':intro12, 
                'title':'The camp of Jingxiong Highway project built by CQCC'
            },
            {
                'url':'/project/cases1/project122.png', 
                'intro':intro12, 
                'title':'The camp of Jingxiong Highway project built by CQCC'
            },
            {
                'url':'/project/cases1/project123.png', 
                'intro':intro12, 
                'title':'The camp of Jingxiong Highway project built by CQCC'
            },
            {
                'url':'/project/cases1/project124.jpg', 
                'intro':intro12, 
                'title':'The camp of Jingxiong Highway project built by CQCC'
            },
            {
                'url':'/project/cases1/project125.jpg', 
                'intro':intro12, 
                'title':'The camp of Jingxiong Highway project built by CQCC'
            },
            {
                'url':'/project/cases1/project126.png', 
                'intro':intro12, 
                'title':'The camp of Jingxiong Highway project built by CQCC'
            },
        ]
    },
    {
        title: 'The campsite of the Jianghe Hui Project was built by the  China Construction Eighth Engineering DIvision',
        intro: intro13,
        class: 'line3',
        type: ['camp', 'rescue'],
        name: 'project13',
        imgList: [
            {
                'url':'/project/cases1/project131.jpg', 
                'intro':intro13, 
                'title':'The campsite of the Jianghe Hui Project was built by the  China Construction Eighth Engineering DIvision'
            },
            {
                'url':'/project/cases1/project132.JPG', 
                'intro':intro13, 
                'title':'The campsite of the Jianghe Hui Project was built by the  China Construction Eighth Engineering DIvision'
            },
            {
                'url':'/project/cases1/project133.JPG', 
                'intro':intro13, 
                'title':'The campsite of the Jianghe Hui Project was built by the  China Construction Eighth Engineering DIvision'
            },
            {
                'url':'/project/cases1/project134.JPG', 
                'intro':intro13, 
                'title':'The campsite of the Jianghe Hui Project was built by the  China Construction Eighth Engineering DIvision'
            },
            {
                'url':'/project/cases1/project135.JPG', 
                'intro':intro13, 
                'title':'The campsite of the Jianghe Hui Project was built by the  China Construction Eighth Engineering DIvision'
            },
            {
                'url':'/project/cases1/project136.JPG', 
                'intro':intro13, 
                'title':'The campsite of the Jianghe Hui Project was built by the  China Construction Eighth Engineering DIvision'
            },
            {
                'url':'/project/cases1/project137.JPG', 
                'intro':intro13, 
                'title':'The campsite of the Jianghe Hui Project was built by the  China Construction Eighth Engineering DIvision'
            },
            {
                'url':'/project/cases1/project138.jpg', 
                'intro':intro13, 
                'title':'The campsite of the Jianghe Hui Project was built by the  China Construction Eighth Engineering DIvision'
            },
            {
                'url':'/project/cases1/project139.jpg', 
                'intro':intro13, 
                'title':'The campsite of the Jianghe Hui Project was built by the  China Construction Eighth Engineering DIvision'
            },
            {
                'url':'/project/cases1/project1310.jpg', 
                'intro':intro13, 
                'title':'The campsite of the Jianghe Hui Project was built by the  China Construction Eighth Engineering DIvision'
            },
        ]
    },
    {
        title: 'Panasonic Xingyao House integrated office project',
        intro: intro14,
        class: 'line2',
        type: ['rescue', 'residential', 'game'],
        name: 'project14',
        imgList: [
            {
                'url':'/project/cases1/project141.png', 
                'intro':intro14, 
                'title':'Panasonic Xingyao House integrated office project'
            },
            {
                'url':'/project/cases1/project142.jpg', 
                'intro':intro14, 
                'title':'Panasonic Xingyao House integrated office project'
            },
            {
                'url':'/project/cases1/project143.jpg', 
                'intro':intro14, 
                'title':'Panasonic Xingyao House integrated office project'
            },
            {
                'url':'/project/cases1/project144.jpg', 
                'intro':intro14, 
                'title':'Panasonic Xingyao House integrated office project'
            },
            {
                'url':'/project/cases1/project145.png', 
                'intro':intro14, 
                'title':'Panasonic Xingyao House integrated office project'
            },
            {
                'url':'/project/cases1/project146.jpg', 
                'intro':intro14, 
                'title':'Panasonic Xingyao House integrated office project'
            },
            {
                'url':'/project/cases1/project147.png', 
                'intro':intro14, 
                'title':'Panasonic Xingyao House integrated office project'
            },
        ]
    },
    {
        title: 'The camp of Terminal T5 transportation hub project built by SCEGCL',
        intro: intro15,
        class: 'line2',
        type: ['camp', 'rescue'],
        name: 'project15',
        imgList: [
            {
                'url':'/project/cases1/project151.jpg', 
                'intro':intro15, 
                'title':'The camp of Terminal T5 transportation hub project built by SCEGCL'
            },
            {
                'url':'/project/cases1/project152.jpg', 
                'intro':intro15, 
                'title':'The camp of Terminal T5 transportation hub project built by SCEGCL'
            },
            {
                'url':'/project/cases1/project153.jpg', 
                'intro':intro15, 
                'title':'The camp of Terminal T5 transportation hub project built by SCEGCL'
            },
            {
                'url':'/project/cases1/project154.jpg', 
                'intro':intro15, 
                'title':'The camp of Terminal T5 transportation hub project built by SCEGCL'
            },
            {
                'url':'/project/cases1/project155.jpg', 
                'intro':intro15, 
                'title':'The camp of Terminal T5 transportation hub project built by SCEGCL'
            },
            {
                'url':'/project/cases1/project156.jpg', 
                'intro':intro15, 
                'title':'The camp of Terminal T5 transportation hub project built by SCEGCL'
            },
        ]
    },
    {
        title: 'Lawson’s mobile convenience stores',
        intro: intro16,
        class: 'line1',
        type: ['game'],
        name: 'project16',
        imgList: [
            {
                'url':'/project/cases1/project161.jpg', 
                'intro':intro16, 
                'title':'Lawson’s mobile convenience stores'
            },
            {
                'url':'/project/cases1/project162.png', 
                'intro':intro16, 
                'title':'Lawson’s mobile convenience stores'
            },
            {
                'url':'/project/cases1/project163.png', 
                'intro':intro16, 
                'title':'Lawson’s mobile convenience stores'
            },
            {
                'url':'/project/cases1/project164.jpg', 
                'intro':intro16, 
                'title':'Lawson’s mobile convenience stores'
            },
            {
                'url':'/project/cases1/project165.jpg', 
                'intro':intro16, 
                'title':'Lawson’s mobile convenience stores'
            },
            {
                'url':'/project/cases1/project166.png', 
                'intro':intro16, 
                'title':'Lawson’s mobile convenience stores'
            },
            {
                'url':'/project/cases1/project167.jpg', 
                'intro':intro16, 
                'title':'Lawson’s mobile convenience stores'
            },
            {
                'url':'/project/cases1/project168.jpg', 
                'intro':intro16, 
                'title':'Lawson’s mobile convenience stores'
            },
            {
                'url':'/project/cases1/project169.jpg', 
                'intro':intro16, 
                'title':'Lawson’s mobile convenience stores'
            },
        ]
    },
    {
        title: 'The milk tea house of Panasonic Hangzhou Industrial Park',
        intro: intro171,
        class: 'line2',
        type: ['game'],
        name: 'project17',
        imgList: [
            {
                'url':'/project/cases1/project171.png', 
                'intro':intro17, 
                'title':'The milk tea house of Panasonic Hangzhou Industrial Park'
            },
            {
                'url':'/project/cases1/project172.jpg', 
                'intro':intro17, 
                'title':'The milk tea house of Panasonic Hangzhou Industrial Park'
            },
            {
                'url':'/project/cases1/project173.jpg', 
                'intro':intro17, 
                'title':'The milk tea house of Panasonic Hangzhou Industrial Park'
            },
            {
                'url':'/project/cases1/project174.jpg', 
                'intro':intro17, 
                'title':'The milk tea house of Panasonic Hangzhou Industrial Park'
            },
            {
                'url':'/project/cases1/project175.jpg', 
                'intro':intro17, 
                'title':'The milk tea house of Panasonic Hangzhou Industrial Park'
            },
            {
                'url':'/project/cases1/project176.JPG', 
                'intro':intro17, 
                'title':'The milk tea house of Panasonic Hangzhou Industrial Park'
            },
            {
                'url':'/project/cases1/project177.JPG', 
                'intro':intro17, 
                'title':'The milk tea house of Panasonic Hangzhou Industrial Park'
            },
            {
                'url':'/project/cases1/project178.JPG', 
                'intro':intro17, 
                'title':'The milk tea house of Panasonic Hangzhou Industrial Park'
            },
            {
                'url':'/project/cases1/project179.JPG', 
                'intro':intro17, 
                'title':'The milk tea house of Panasonic Hangzhou Industrial Park'
            },
            {
                'url':'/project/cases1/project1710.jpg', 
                'intro':intro17, 
                'title':'The milk tea house of Panasonic Hangzhou Industrial Park'
            },
            {
                'url':'/project/cases1/project1711.png', 
                'intro':intro17, 
                'title':'The milk tea house of Panasonic Hangzhou Industrial Park'
            },
            {
                'url':'/project/cases1/project1712.png', 
                'intro':intro17, 
                'title':'The milk tea house of Panasonic Hangzhou Industrial Park'
            },
        ]
    },
    {
        title: 'Panasonic Health Care Exploration Center',
        intro: intro18,
        class: 'line11',
        type: ['residential'],
        name: 'project18',
        imgList: [
            {
                'url':'/project/cases1/project181.jpg', 
                'intro':intro18, 
                'title':'Panasonic Health Care Exploration Center'
            },
            {
                'url':'/project/cases1/project182.JPG', 
                'intro':intro18, 
                'title':'Panasonic Health Care Exploration Center'
            },
            {
                'url':'/project/cases1/project183.jpg', 
                'intro':intro18, 
                'title':'Panasonic Health Care Exploration Center'
            },
            {
                'url':'/project/cases1/project184.jpg', 
                'intro':intro18, 
                'title':'Panasonic Health Care Exploration Center'
            },
            {
                'url':'/project/cases1/project185.png', 
                'intro':intro18, 
                'title':'Panasonic Health Care Exploration Center'
            },
            {
                'url':'/project/cases1/project186.jpg', 
                'intro':intro18, 
                'title':'Panasonic Health Care Exploration Center'
            },
            {
                'url':'/project/cases1/project187.jpg', 
                'intro':intro18, 
                'title':'Panasonic Health Care Exploration Center'
            },
            {
                'url':'/project/cases1/project188.jpg', 
                'intro':intro18, 
                'title':'Panasonic Health Care Exploration Center'
            },
            {
                'url':'/project/cases1/project189.jpg', 
                'intro':intro18, 
                'title':'Panasonic Health Care Exploration Center'
            },
        ]
    },
    {
        title: 'Lab3 Jiangyin Experimental Building',
        intro: intro191,
        class: 'line1',
        type: ['residential'],
        name: 'project19',
        imgList: [
            {
                'url':'/project/cases1/project191.jpg', 
                'intro':intro19, 
                'title':'Lab3 Jiangyin Experimental Building'
            },
            {
                'url':'/project/cases1/project192.png', 
                'intro':intro19, 
                'title':'Lab3 Jiangyin Experimental Building'
            },
            {
                'url':'/project/cases1/project193.png', 
                'intro':intro19, 
                'title':'Lab3 Jiangyin Experimental Building'
            },
            {
                'url':'/project/cases1/project194.png', 
                'intro':intro19, 
                'title':'Lab3 Jiangyin Experimental Building'
            },
            {
                'url':'/project/cases1/project195.jpg', 
                'intro':intro19, 
                'title':'Lab3 Jiangyin Experimental Building'
            },
        ]
    },
    {
        title: 'The camp of Xinxiang Marriott Hotel built by the China Construction Seventh Engineering DIvision',
        intro: intro20,
        class: 'line3',
        type: ['camp'],
        name: 'project20',
        imgList: [
            {
                'url':'/project/cases2/project201.jpg', 
                'intro':intro20, 
                'title':'The camp of Xinxiang Marriott Hotel built by the China Construction Seventh Engineering DIvision'
            },
            {
                'url':'/project/cases2/project202.png', 
                'intro':intro20, 
                'title':'The camp of Xinxiang Marriott Hotel built by the China Construction Seventh Engineering DIvision'
            },
            {
                'url':'/project/cases2/project203.png', 
                'intro':intro20, 
                'title':'The camp of Xinxiang Marriott Hotel built by the China Construction Seventh Engineering DIvision'
            },
            {
                'url':'/project/cases2/project204.jpg', 
                'intro':intro20, 
                'title':'The camp of Xinxiang Marriott Hotel built by the China Construction Seventh Engineering DIvision'
            },
            {
                'url':'/project/cases2/project205.jpg', 
                'intro':intro20, 
                'title':'The camp of Xinxiang Marriott Hotel built by the China Construction Seventh Engineering DIvision'
            },
        ]
    },
    {
        title: 'Ice Cube of CSCEC 1th Group',
        intro: intro21,
        class: 'line1',
        type: ['camp'],
        name: 'project21',
        imgList: [
            {
                'url':'/project/cases2/project211.jpg', 
                'intro':intro21, 
                'title':'Ice Cube of CSCEC 1th Group'
            },
            {
                'url':'/project/cases2/project212.jpg', 
                'intro':intro21, 
                'title':'Ice Cube of CSCEC 1th Group'
            },
        ]
    },
    {
        title: 'The camp of Yilai high-speed project built by CQCC',
        intro: intro22,
        class: 'line2',
        type: ['camp', 'rescue'],
        name: 'project22',
        imgList: [
            {
                'url':'/project/cases2/project221.jpg', 
                'intro':intro22, 
                'title':'The camp of Yilai high-speed project built by CQCC'
            },
            {
                'url':'/project/cases2/project222.png', 
                'intro':intro22, 
                'title':'The camp of Yilai high-speed project built by CQCC'
            },
            {
                'url':'/project/cases2/project223.jpg', 
                'intro':intro22, 
                'title':'The camp of Yilai high-speed project built by CQCC'
            },
            {
                'url':'/project/cases2/project224.jpg', 
                'intro':intro22, 
                'title':'The camp of Yilai high-speed project built by CQCC'
            },
        ]
    },
    {
        title: "Xi'an Quarantine Hospital",
        intro: intro23,
        class: 'line1',
        type: ['camp', 'rescue'],
        name: 'project23',
        imgList: [
            {
                'url':'/project/cases2/project231.jpg', 
                'intro':intro23, 
                'title':"Xi'an Quarantine Hospital"
            },
            {
                'url':'/project/cases2/project232.jpeg', 
                'intro':intro23, 
                'title':"Xi'an Quarantine Hospital"
            },
            {
                'url':'/project/cases2/project233.jpeg', 
                'intro':intro23, 
                'title':"Xi'an Quarantine Hospital"
            },
        ]
    },
]

const ProjectCasesPage = () => {
    const [imgName, setImgName] = React.useState('')
    const [imgShow, setImgShow] = React.useState(false)
    const [photoIndex, setPhotoIndex] = React.useState(0)
    const [caseType, setCaseType] = React.useState('all');
    const [showCaseList, setShowCaseList] = React.useState(caseList)

    useEffect(()=>{
        let search = window.location.href
        if(search) {
            const name = url.parse(search, true).query.name;
            if(name) {
                setImgName(name)
                setImgShow(true)
            }else {
                setImgName('')
                setImgShow(false)
                setPhotoIndex(0)
            }
        }
    }, [typeof window !== 'undefined' ? window.location.href : ''])

    const changeCaseType = (type) =>{
        // ['camp', 'china', 'rescue', 'residential', 'game']
        setCaseType(type);
        let list = [];
        if(type == 'all') {
            list = caseList;
        }else {
            list = caseList.filter((item)=> {return item.type.indexOf(type) != -1});
        }
        setShowCaseList(list);
    }
    const showCurrentImgs = (imgsName) =>{
        window.location.href = "/project?name="+imgsName
    }
    const hiddenImgs = () =>{
        setImgName('')
        setImgShow(false)
        setPhotoIndex(0)
    }

    return (
        <Layout>
            <div className="bocsapce-introduce" >
                <img className="content-img" src="/product/1.png" />
            </div>
            <div className="bocsapce-case" >
                <div className="case-title">Project Cases</div>
                {/* <div style={{textAlign: 'center'}}>
                    <div className="case-choose">
                        <div 
                            className={caseType == 'all' ? "case-choose-item checked" : "case-choose-item"} 
                            onClick={(()=>{changeCaseType('all')})}>
                            All
                        </div>
                        <div className="case-choose-item-border"></div>
                        <div 
                            className={caseType == 'camp' ? "case-choose-item checked" : "case-choose-item"} 
                            onClick={(()=>{changeCaseType('camp')})}>
                            Camp
                        </div>
                        <div className="case-choose-item-border"></div>
                        <div 
                            className={caseType == 'rescue' ? "case-choose-item checked" : "case-choose-item"} 
                            onClick={(()=>{changeCaseType('rescue')})}>
                            Emergency
                        </div>
                        <div className="case-choose-item-border"></div>
                        <div 
                            className={caseType == 'residential' ? "case-choose-item checked" : "case-choose-item"} 
                            onClick={(()=>{changeCaseType('residential')})}>
                            Residential 
                        </div>
                        <div className="case-choose-item-border"></div>
                        <div 
                            className={caseType == 'game' ? "case-choose-item checked" : "case-choose-item"} 
                            onClick={(()=>{changeCaseType('game')})}>
                            Commercial
                        </div>
                    </div>
                </div> */}
                <div className="case-list">
                    {
                        showCaseList && showCaseList.map((iItem, iIndex) => {
                            return (
                                <div key={iIndex} className="case-list-item">
                                    <img style={{width:'412px', height:'253px'}} src={iItem.imgList[0].url} />
                                    <div className={iItem.class}>
                                        {iItem.title}
                                    </div>
                                    <div className="case-item-border"></div>
                                    <div className="case-item-text">
                                        {iItem.intro}
                                    </div>
                                    <div className="case-btn" onClick={()=> {showCurrentImgs(iItem.name)}}>
                                        MORE
                                    </div>
                                    <PhotoSlider
                                        images={iItem.imgList.map(item => ({ src: item.url, intro:item.intro }))}
                                        visible={imgName===iItem.name&&imgShow?true:false}
                                        introVisible={true}
                                        onClose={() => hiddenImgs()}
                                        index={photoIndex}
                                        onIndexChange={setPhotoIndex}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </Layout>
    )
}

export default ProjectCasesPage

